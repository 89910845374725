import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './MobileSlider.css'; 
import image0 from '../Web_Shuffle2/0000.png';
import image20 from '../Web_Shuffle2/0020.png';
import image40 from '../Web_Shuffle2/0040.png';
import image60 from '../Web_Shuffle2/0060.png';
import image80 from '../Web_Shuffle2/0080.png';
import Footer2 from './Footer2';
import Footer from './Footer';
import scrollGif from './images/scrollformore.gif';
import subscribeUser from '../Mailchimp/MailchimpConnection';


const MyCollection = [
    {
        title: "KEEP TRACK OF YOUR INTAKE",
        label: "Track calories, micronutrients, and receive personalized nutrition insights to optimize your health.",
        imgPath: image0, 
        
    },
    {
        title: "BE ON TOP OF YOUR TRAINING GAME",
        label: "Track workout data such as reps and sets and see progress being made in real time!",
        imgPath: image20,
    },
    {
        title: "EASY TO STAY ON TRACK",
        label: "Gain an overview of past achievements and upcoming tasks, all conveniently in one place, making it effortless to reach your goals.",
        imgPath: image40, 
    },
    {
        title: "CHART YOUR SUCCESS",
        label: "Discover trends related to your goals and identify areas for improvement in your overall health profile to enhance well-being and performance.",
        imgPath: image60,
    },
    {
        title: "CONTROL YOUR HEALTH AND PERFORMANCE",
        label: "We will notify you when Stafly is available.",
        imgPath: image80, 
    },
];



function OpenPDF() {
    window.open('/Privacy_Policy.pdf', '_blank');
}


function MobileSlider() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [autoLoop, setAutoLoop] = useState(true); 
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // Handle click on a dot
    const handleDotClick = (index) => {
        setCurrentIndex(index);
        setAutoLoop(false); 
    };

    // Handle next slide
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === MyCollection.length - 1 ? 0 : prevIndex + 1));
    };

    // Handle previous slide
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? MyCollection.length - 1 : prevIndex - 1));
    };

    // Touch start event handler
    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    // Touch move event handler
    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    // Touch end event handler
    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isSwipe = Math.abs(distance) > 50;
        if (isSwipe) {
            if (distance > 0) {
                handleNext();
            } else {
                handlePrev();
            }
        }
        setTouchStart(null);
        setTouchEnd(null);
    };

    useEffect(() => {
        let interval;
        if (autoLoop) {
            interval = setInterval(handleNext, 6000);
        }
        return () => clearInterval(interval);
    }, [autoLoop]); 

    return (
        <div className="EntireContainer">
            <div className="slider-container"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="slider-content">
                    {MyCollection.map((item, index) => (
                        <div key={index} className={`slide ${index === currentIndex ? 'active' : ''}`}>
                            <h2 className="image-title">{item.title}</h2>
                            <h3 className="image-label">{item.label}</h3>
                            <img
                                className="ImageMobile"
                                width="580"
                                height="900"
                                src={item.imgPath}
                                style={{
                                    width: "70%",
                                }}
                                alt={item.label}
                            />
                        </div>
                    ))}
                </div>
                <div className="dots-container">
                    {MyCollection.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        ></span>
                    ))}
                </div>
                <img src={scrollGif} loading="lazy" width="1024" height="1024" alt="Learn more about the Staflyapp by Stafly, which has tools to track nutrition, training and wellbeing." className="ScrollToFind2" />
                <MobileSliderBody2/>
                <Footer2 />
            </div>
            {/*<img src={require('./images/Group 1059.png')} alt="Welleo120" className="Scroll" />*/}
        </div>
    );
}

function MobileSliderBody2() {
    const [opacity, setOpacity] = useState(0);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // Added to track message type (success or error)
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Invalid email';
        }

        const domainRegex = /\.(com|se|ru|net|org|info|biz|gov|edu|mil|int|name|pro|aero|coop|museum|xyz|top|site|online|tech|store|art|blog|club|design|space|wiki|shop|us|uk|ca|de|fr|es|it|nl|au|ch|jp|cn|in)$/i;
        if (!domainRegex.test(email)) {
            return 'Invalid email';
        }

        return '';
    };

    const handleChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        const validationError = validateEmail(newEmail);
        setMessage(validationError);
        setMessageType(validationError ? 'error' : '');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const validationError = validateEmail(email);
        if (validationError) {
            setMessage(validationError);
            setMessageType('error');
            setIsLoading(false);
            return;
        }

        try {
            await subscribeUser(email, isChecked2, isChecked);
            setMessage('Email submitted successfully!');
            setMessageType('success');
        } catch (error) {
            setMessage(`Failed to submit email: ${error.message}`);
            setMessageType('error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleCheckboxChange2 = (event) => {
        setIsChecked2(event.target.checked);
    };
    return (
        <div className="MobileSignUp">
            <div className="MobileContent1">
                    <div className="Content1">
                        <h2 className="H12">CONTROL YOUR HEALTH AND PERFORMANCE</h2>
                        <p className="SignUpP">We will notify you when Stafly is available.</p>
                        <form onSubmit={handleSubmit} className="InputButton">
                            <input
                                type="email"
                                placeholder="Enter Email"
                                className="Input-Body2"
                                value={email}
                                onChange={handleChange}
                                required
                            />
                            <div className="CheckBoxDiv">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    name="newsletter"
                                    value="subscribe"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="checkboxText" htmlFor="checkbox">
                                    Send me regular health and fitness updates
                                </label>
                            </div>
                            <div className="CheckBoxDiv">
                                <input
                                    type="checkbox"
                                    id="checkbox2"
                                    name="newsletter"
                                    value="subscribe"
                                    checked={isChecked2}
                                    onChange={handleCheckboxChange2}
                            />
                            <label className="checkboxText" htmlFor="checkbox2">
                                By signing up, you confirm that you are 13 years old or older, have read and agree with the
                                <a href="#" className="colored-text" onClick={(event) => OpenPDF(event)}>Privacy Policy</a>
                            </label>
                            </div>
                            <button type="submit" className="SignUpButton buttonSign" disabled={!isChecked2 || isLoading}>
                                {isLoading ? 'Processing...' : 'SIGN UP'}
                            </button>
                            <div className="ResponseMessage">
                                {message && <p className={`message ${messageType}`}>{message}</p>}
                            </div>
                        </form>

                    </div>
                    <div className="Content2">
                    <img src={require('../Web_Shuffle2/0080.png')} alt="The Stafly app gives user tools to track nutrition, training and wellbeing" className="Image" loading="lazy" width="580" height="900" />
                    </div>
            </div>
        </div>
    );
}

export default MobileSlider;
